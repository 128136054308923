<template>

  <!--    <metainfo>
        <template v-slot:title="{ content }">{{ content ? `${content} | SITE_NAME` : `SITE_NAME` }}</template>
      </metainfo>-->

  <component v-if="meta.layout === 'scan'"
             :is="resolveLayout"
             :user="user"
             :user_id="user.id"
  >
    <router-view></router-view>
  </component>

  <v-app v-else>
<!--
        <p-w-a-prompt></p-w-a-prompt>-->

    <vertical-nav-menu
      :user="user"
      :user_id="user.id"
      :is-drawer-open.sync="isDrawerOpen"
    ></vertical-nav-menu>
    <v-layout>

      <template v-if="meta.layout === 'blank'">

        <v-app-bar
          app
          density="compact"
          elevation="0"
        >
          <div class="boxed-container w-full">
            <div class="d-flex align-center mx-lg-6">
              <!-- Left Content -->
              <v-app-bar-nav-icon
                class="d-block d-xs-none me-2"
                @click="isDrawerOpen = !isDrawerOpen"
              >
                <v-img
                  width="30"
                  contain
                  alt=""
                  :src="require('@/assets/images/avatars/logo.png')"
                ></v-img>
              </v-app-bar-nav-icon>

              <v-app-bar-title>Reader</v-app-bar-title>
              <v-spacer></v-spacer>

              <!-- Right Content
              <a
                href="https://3ag-edition.com/"
                target="_blank"
                rel="nofollow"
                class="mr-3"
              >
                <v-img
                  width="30"
                  contain
                  alt=""
                  :src="require('@/assets/images/avatars/logo.png')"
                ></v-img>
              </a> -->
              <theme-switcher class="mr-3"></theme-switcher>
              <app-bar-user-menu
                :user="user"
                :user_id="user.id"
              ></app-bar-user-menu>
            </div>
          </div>
        </v-app-bar>

        <bottom-navigation></bottom-navigation>
      </template>
      <template v-else >

        <v-app-bar
          app
          density="compact"
          elevation="0"
        >
          <div class="boxed-container w-full">
            <div class="d-flex align-center mx-lg-6">
              <!-- Left Content -->
              <v-app-bar-nav-icon
                class="d-block d-xs-none me-2"
                @click="isDrawerOpen = !isDrawerOpen"
              >
                <v-img
                  width="30"
                  contain
                  alt=""
                  :src="require('@/assets/images/avatars/logo.png')"
                ></v-img>
              </v-app-bar-nav-icon>

              <v-app-bar-title>Reader</v-app-bar-title>
              <v-spacer></v-spacer>

              <!-- Right Content
              <a
                href="https://3ag-edition.com/"
                target="_blank"
                rel="nofollow"
                class="mr-3"
              >
                <v-img
                  width="30"
                  contain
                  alt=""
                  :src="require('@/assets/images/avatars/logo.png')"
                ></v-img>
              </a> -->
              <theme-switcher class="mr-3"></theme-switcher>
              <app-bar-user-menu
                :user="user"
                :user_id="user.id"
              ></app-bar-user-menu>
            </div>
          </div>
        </v-app-bar>

      </template>

      <component :is="resolveLayout"
                 :user="user"
                 :user_id="user.id"
                 @setTitle="setTitle"
      >
        <router-view></router-view>
      </component>

      <template v-if="meta.layout !== 'scan'">
      </template>
      <v-dialog
        v-model="$store.state.authentication"
        max-width="600"
        fullscreen
      >
        <login-component
          :newaccount="false"
          @logged="logged"
          @closedialog="()=> {$store.commit('updateAuthentication', false)}"
        ></login-component>
      </v-dialog>

    </v-layout>
  </v-app>

</template>

<script>
import {computed, ref} from '@vue/composition-api'
import {useRouter} from '@/utils'

import LayoutBlank from '@/layouts/Blank.vue'
import LayoutReader from '@/layouts/Reader.vue'
import LayoutContent from '@/layouts/Content.vue'
import LayoutCommunity from '@/layouts/Community.vue'
import LayoutHome from '@/layouts/Home.vue'
import UpgradeToPro from './components/UpgradeToPro.vue'
import {Drequest} from '@/plugins/Drequest'
import FlatScreen from '@/layouts/components/FlatScreen'
import VerticalNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'

import BottomNavigation from '@/layouts/components/BottomNavigation.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import PWAPrompt from '@/layouts/components/PWAPrompt.vue'
import {
  mdiAccessPoint,
  mdiArrowLeft,
  mdiBookOpenPageVariantOutline,
  mdiAccountGroupOutline,
  mdiAccountOutline,
  mdiBellOutline, mdiChevronLeft,
  mdiCogOutline,
  mdiCreation,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiGamepadCircleOutline,
  mdiGithub,
  mdiGoogle,
  mdiGrid,
  mdiMagnify,
  mdiTwitter
} from "@mdi/js";
import ShareButtonComponent from "@/components/ShareButtonComponent.vue";
import store from "@/store";
import LoginComponent from "@/components/LoginComponent.vue";
// import firebaseMessaging from './firebase'
// import { getMessaging, onMessage } from 'firebase/messaging'

export default {
  /*metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'BD, Manga, Webtoons, Light novels et artbooks gratuit en ligne - 3agReader',
    content: "Publiez et lisez gratuitement vos mangas, bandes dessinées, webtoons et light novels en ligne. Découvrez des talents, lisez-les, soutenez-les.",
    // all titles will be injected into this template
    titleTemplate: '%s | Lecture en ligne et de Creation de contenu, Bande dessine, Manga, Comics et bien d autre'
  },*/
  /*metaInfo() {
    return {
      htmlAttrs: {
        lang: 'fr',
        amp: true
      },
      ...this.$store.state.metatag
    }
  },*/
  beforeMount() {
    store.dispatch('init')
    this.$vuetify.theme.dark = this.$store.state.dark_mode
  },
  mounted() {
    /* const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7691146384467908"
    )
    recaptchaScript.setAttribute("async", true)
    document.head.appendChild(recaptchaScript)
    console.log('Firebase cloud messaging object', this.$messaging)
    */
  },
  components: {
    LoginComponent,
    ShareButtonComponent,
    PWAPrompt,
    AppBarUserMenu,
    ThemeSwitcher,
    BottomNavigation,
    FlatScreen,
    LayoutBlank,
    LayoutContent,
    LayoutReader,
    LayoutCommunity,
    LayoutHome,
    UpgradeToPro,
    VerticalNavMenu,
  },
  methods:{
    logged ( response) {
      console.log(response)
      window.location.reload()
    }
  },

  /* sockets: {
    connect: function () {
      console.log('socket connected')
    },
    customEmit: function (data) {
      console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    }
  }, */
  setup() {

    /*  useMeta({
       title: '',
       htmlAttrs: { lang: 'en', amp: true }
     }) */

    const {route} = useRouter()
    const user = ref(Drequest.getUser())
    console.log(user)
    const meta = computed(() => {
      return route.value.meta;
    });
    store.dispatch('init')
    const resolveLayout = computed(() => {
      // Handles initial route
      const uuid = localStorage.getItem('uuid_session')
      if (!uuid) {
        Drequest.init('https://www.uuidgenerator.net/api/version4')
          .get(uuid => {
            console.log(uuid)
            localStorage.setItem('uuid_session', uuid)
          }, {
            dataType: 'text',
          })
      } else {
        console.log(uuid)
      }

      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'scan') return 'layout-reader'
      if (route.value.meta.layout === 'home') return 'layout-home'
      if (route.value.meta.layout === 'community') return 'layout-community'

      return 'layout-content'
    })

    const appbar = ref({})
    const setTitle = (title, url) => {
      appbar.value = {title: title, url: url}
    }
    //const meta = ref(route.value.meta)
    const tabs = ref(1)
    const isDrawerOpen = ref(false)
    const dialog = ref(false)

    const bnav = ref(0)
    return {
      setTitle,

      appbar,
      meta,
      isDrawerOpen,
      bnav,
      tabs,
      user,
      resolveLayout,
      dialog,
      window: window,
      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
        mdiAccountOutline,
        mdiTwitter,
        mdiGoogle,
        mdiEyeOutline,
        mdiCogOutline,
        mdiCreation,
        mdiGrid,
        mdiAccountGroupOutline,
        mdiAccessPoint,
        mdiGamepadCircleOutline,
        mdiEyeOffOutline,
        mdiChevronLeft,
        mdiArrowLeft,
        mdiBookOpenPageVariantOutline,
      },
    }
  },
}
</script>

<style>
.text--green-darken-2 {
  color: #388E3C;
}

.text--primary2 {
  color: #f2142b !important;
}
</style>
