<template>

  <v-btn
    v-if="!user_id"
    icon small
    to="/auth/login"
  >
    <v-icon>{{ icons.mdiAccountOutline }}</v-icon>
  </v-btn>

  <div class="" v-else>

    <v-btn icon class="mr-3"
           color="deep-purple accent-4"
           to="/messenger" small
    >
<!--      <v-badge
        v-if="notify.unreaded > 0"
        :value="notify.unreaded"
        color="primary"
        :content="notify.unreaded"
      >
        <v-icon
        >
          {{ icons.mdiChatOutline }}
        </v-icon>
      </v-badge>-->
      <v-icon
      >
        {{ icons.mdiChatOutline }}
      </v-icon>
    </v-btn>

    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon class="mr-3"
               color="deep-purple accent-4"
               v-bind="attrs"
               v-on="on" small
        >
          <v-badge
            v-if="notify.unreaded > 0"
            :value="notify.unreaded"
            color="primary"
            :content="notify.unreaded"
          >
            <v-icon
            >
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-badge>
          <v-icon v-else
          >
            {{ icons.mdiBellOutline }}
          </v-icon>
        </v-btn>
      </template>
      <notification-component :limit="15" :user="user">
        <template #title>

          <div class="pb-3 pt-2">
            <h3>Notifications</h3>
          </div>
        </template>
        <template #more>

          <v-divider class="my-2"></v-divider>
          <v-list-item
            :to="'/notifications'"
            link
          >
            <v-list-item-icon class="me-2">
              <v-icon size="22">
                {{ icons.mdiCogOutline }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Voir toutes les notifications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </template>
      </notification-component>
<!--      <v-list>

        <v-divider></v-divider>

        <v-divider class="my-2"></v-divider>

        &lt;!&ndash; Settings &ndash;&gt;
        <v-list-item
          :to="'/notifications'"
          link
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Voir toutes les notifications</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>-->
    </v-menu>

    <v-menu
      offset-y
      left
      nudge-bottom="14"
      min-width="230"
      content-class="user-profile-menu-content"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          bottom
          color="success"
          overlap
          offset-x="12"
          offset-y="12"
          class="ms-4"
          dot
        >

          <span
            v-bind="attrs"
            v-on="on">
          <user-avatar :user="user" :size="36" ></user-avatar></span>

        </v-badge>
      </template>
      <v-list>
        <div class="pb-3 pt-2">
          <v-badge
            bottom
            color="success"
            overlap
            offset-x="12"
            offset-y="12"
            class="ms-4"
            dot
          >
            <user-avatar :user="user" :size="42" ></user-avatar>
          </v-badge>
          <div
            class="d-inline-flex flex-column justify-center ms-3"
            style="vertical-align:middle"
          >
          <span class="text--primary font-weight-semibold mb-n1">
            {{ user.username }}
          </span>
            <small class="text--disabled text-capitalize">{{ user.email }} /
              {{ user.phonenumber }}</small>
          </div>
        </div>

        <v-divider></v-divider>

        <!-- Profile -->
        <v-list-item
          link
          :to="'/profile/'+user.id"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiAccountOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          link
          :to="'/creator/dashboard'"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiDraw }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Espace Createur</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Settings -->
        <v-list-item
          :to="'/settings'"
          link
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCogOutline }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Paramètres</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- Pricing -->
        <v-list-item
          to="/wallet"
          link
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiCurrencyUsd }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Mon portefeuille</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="my-2"></v-divider>

        <!-- Logout -->
        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-icon class="me-2">
            <v-icon size="22">
              {{ icons.mdiLogoutVariant }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Deconnexion</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant, mdiBellOutline, mdiCreation, mdiDraw
} from '@mdi/js'
import {onMounted, ref, watch} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import UserAvatar from "@/components/UserAvatar.vue";
import NotificationComponent from "@/components/NotificationComponent.vue";

export default {
  components: {NotificationComponent, UserAvatar},
  props: {
    user: {
      type: Object,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
  },
  mounted() {
  },
  watch: {},
  setup(props) {

    const notify = ref({
      unreaded: 0
    })

    const initNotify = () => {

      Drequest.api('notificationbroadcasted.alertuser?dfilters=on&user_id=' + props.user_id)
        .get(response => {
          console.log(response)
          notify.value = response
        })

    }
    initNotify()
    const logout = () => {
      Drequest.logout()
      window.location.href = '/settings'
    }

    const notified = () => {
      if (!notify.value.unreaded) {
        return 0
      }

      var ids = []
      for (let el of notify.value.notifications) {
        ids.push(el.id)
      }

      Drequest.api('notified')
        .toFormdata({'ids': ids.join()})
        .post(response => {
          console.log(response)
          notify.value.unreaded = 0
        })

    }

    return {
      logout,
      notified,

      notify,

      icons: {
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiBellOutline,
        mdiCreation,
        mdiDraw,
      },
    }
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
