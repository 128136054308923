import Vue from 'vue'
import VueRouter from 'vue-router'
import $ from 'jquery'
import { Drequest } from '@/plugins/Drequest'
import store from "@/store";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/pages/Home.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/social',
    name: 'social',
    component: () => import('@/views/pages/Social.vue'),
    meta: {
      layout: 'blank',
      appbar: 'community',
      advertising: true
    },
  },
  {
    path: '/community',
    name: 'community',
    component: () => import('@/views/pages/CommunityMember.vue'),
    meta: {
      layout: 'content',
      advertising: true
    },
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import('@/views/pages/Gallery.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/pages/Activity.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/activity/:id',
    name: 'activity-detail',
    component: () => import('@/views/pages/Activity.vue'),
    meta: {
      layout: 'content',
    },
  },
  /* {
    path: '/social/:idprofile',
    name: 'social_user',
    component: () => import('@/views/pages/Social.vue'),
    meta: {
      layout: 'content',
    },
  }, */
  {
    path: '/catalog',
    name: 'catalog',
    component: () => import('@/views/pages/Catalog.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/creator',
    name: 'creator',
    component: () => import('@/views/pages/Creator.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/creator/dashboard',
    name: 'creator-dash',
    component: () => import('@/views/pages/CreatorProfile.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/creator/comicbook/:id',
    name: 'creator-cb',
    component: () => import('@/views/pages/CreatorComicbookDetail.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/chapter/form',
    name: 'newchapter',
    component: () => import('@/views/pages/ChapterForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/chapter/form/:idchapter',
    name: 'editchapter',
    component: () => import('@/views/pages/ChapterForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/chapters',
    name: 'chapters',
    component: () => import('@/views/pages/Chapters.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/comicbook/form',
    name: 'newcomicbook',
    component: () => import('@/views/pages/ComicbookForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/comicbook/form/:idcomic',
    name: 'comicbookedit',
    component: () => import('@/views/pages/ComicbookForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/comicbook/new-chapter/:idcomic',
    name: 'new-chapter',
    component: () => import('@/views/pages/ChapterForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/comicbook/:comicbook/:id',
    name: 'comicbook',
    component: () => import('@/views/pages/Comicbook.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/one-shot/:comicbook/:id',
    name: 'one-shot',
    component: () => import('@/views/pages/OneShot.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/messenger/:id',
    name: 'converse',
    component: () => import('@/views/pages/Messenger.vue'),
    meta: {
      layout: 'scan',
    },
  },
  {
    path: '/messenger',
    name: 'messenger',
    component: () => import('@/views/pages/Messenger.vue'),
    meta: {
      layout: 'scan',
    },
  },
  {
    path: '/illustration/form',
    name: 'illustration-form',
    component: () => import('@/views/pages/IllustrationForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/illustration/form/:id',
    name: 'illustrationedit',
    component: () => import('@/views/pages/IllustrationForm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/illustration/:id',
    name: 'illustration',
    component: () => import('@/views/pages/Illustration.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/scan/:comicbook/:id',
    name: 'scan_c_id',
    component: () => import('@/views/pages/Scan.vue'),
    meta: {
      layout: 'scan',
    },
  },
  {
    path: '/scan/:comicbook/:number/:page',
    name: 'scan_cnp',
    component: () => import('@/views/pages/Scan.vue'),
    meta: {
      layout: 'scan',
    },
  },
  {
    path: '/scan/:comicbook/:number/:page/:title',
    name: 'scan_title',
    component: () => import('@/views/pages/Scan.vue'),
    meta: {
      layout: 'scan',
    },
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@/views/pages/Notifications.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/top-ranking',
    name: 'top-ranking',
    component: () => import('@/views/pages/TopRanking.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contests',
    name: 'contests',
    component: () => import('@/views/pages/Contests.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contest/:id',
    name: 'contest',
    component: () => import('@/views/pages/Contest.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contest/participant/:id',
    name: 'contest-participant',
    component: () => import('@/views/pages/ContestEnroll.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contest/:participantid/enroll',
    name: 'contest-enroll',
    component: () => import('@/views/pages/OneShot.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/pages/Settings.vue'),
  },
  {
    path: '/profile/:idprofile',
    name: 'profile',
    component: () => import('@/views/pages/Profile.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/profile/:idprofile/activities',
    name: 'profile-activities',
    component: () => import('@/views/pages/Social.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/profile/:idprofile/followers',
    name: 'profile-followers',
    component: () => import('@/views/pages/ProfileFollowers.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import('@/views/pages/Wallet.vue'),
  },
  {
    path: '/favoris',
    name: 'favoris',
    component: () => import('@/views/pages/Purchase.vue'),
  },
  {
    path: '/subscription',
    name: 'subscription',
    component: () => import('@/views/pages/Subscription.vue'),
  },
  {
    path: '/subscription/:option',
    name: 'subscriptionoption',
    component: () => import('@/views/pages/Subscription.vue'),
  },
  {
    path: '/faq/',
    name: 'faq',
    component: () => import('@/views/pages/Faq.vue'),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/auth/login',
    name: 'pages-login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/auth/register',
    name: 'pages-register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'home',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.NODE_ENV === 'production' ? '/' : '/',
  //base: process.env.NODE_ENV === 'production' ? '/edition3ag/' : '/',
  // base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  console.log(to.meta)
  if (to.meta.advertising){
    console.log('handle ads')
    setTimeout(()=>{
      var iframe = $('iframe:last-child')
      console.log(iframe)
      if (iframe) {
        // iframe.css('position', 'absolute')
        // iframe.css('top', '0')
        Drequest.__ads = iframe
        console.log(iframe)
      }
    }, 6000)

  }else{
    var iframe = $('iframe:last-child')
    console.log(iframe)
    if (iframe) {
      iframe.css('display', 'none')
      // iframe.css('top', '0')
      Drequest.__ads = iframe
      console.log(iframe)
    }
  }
  if (to.meta.needsAuthen) {
    if (store.getters.isLoggedIn) {
      next()
    }
    else {
      next("/auth/login")
    }
  } else if (['pages-login', 'pages-register'].includes(to.meta.name)) {
    if (store.getters.isLoggedIn)
      next("/home")
    else
      next()
  } else {
    next()
  }
})

export default router
