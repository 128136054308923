import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
//import Meta from 'vue-meta'
// import { createMetaManager } from 'vue-meta'
import VueSocialSharing from 'vue-social-sharing'


import App from './App.vue'
// import VueSocketIO from 'vue-socket.io'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './registerServiceWorker'
import Adsense from 'vue-google-adsense/dist/Adsense.min.js'
import InArticleAdsense from 'vue-google-adsense/dist/InArticleAdsense.min.js'
import InFeedAdsense from 'vue-google-adsense/dist/InFeedAdsense.min.js'
// import ReadMore from 'vue-read-more';

//import {createI18n} from 'vue-i18n'
/* import VueAnalytics from 'vue-analytics'

Vue.use(VueAnalytics, {
  id: 'G-KQ1DZZGR5R', // analytic de reader
  router
} */

/*import en from './locales/en.json'
import fr from './locales/fr.json'
const i18n = createI18n({
  locale: 'en', // set locale
  //fallbackLocale: 'en', // set fallback locale
  ...{
    en:en,
    fr:fr,
  },
  // something vue-i18n options here ...
})*/
/* head.script.push({
  async: true,
  src: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js',
  dataAdClient: "ca-pub-yourId"
}); */

Vue.config.productionTip = false
Vue.use(require('vue-script2'))

Vue.use(Adsense)
Vue.use(InArticleAdsense)
Vue.use(InFeedAdsense)

Vue.use(VueSocialSharing)

/*Vue.use(Meta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})*/
// Vue.use(ReadMore);

/* Vue.use(new VueSocketIO({
  debug: true,
  connection: 'http://localhost:3000',
  //connection: 'https://guarded-eyrie-49546.herokuapp.com',
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  options: { withCredentials: false } //Optional options
})) */
Vue.use(VueAxios, axios)
const app = new Vue({
  //i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
})

//Vue.prototype.$messaging = firebaseMessaging
// app.config.globalProperties.$messaging = firebaseMessaging
// await router.re
app.$mount('#app')
