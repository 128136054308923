<template>
  <v-list class="bg-gradient-primary" dense>
    <template v-for="(item, index) in postcommentll.listEntity">
      <v-divider
        :key="index"
      ></v-divider>
      <v-list-item dense
                   :key="item.id" class="align-start"
      >
        <user-avatar
          class="mr-3" :active="false" :user="item.user" :size="42"></user-avatar>
        <v-list-item-content>
          <p class=" ">
            <b class="mr-3">{{ item.username }}</b>
            <span class="text-sm" v-html="item.comment"></span>
            <br>
            <small class="mr-3 v-size&#45;&#45;small"><duration :last-date="item.created_at"></duration></small>
          </p>

          <v-list-item-subtitle class="d-flex justify-space-between align-center">
            <v-btn
              @click="$emit('reply',{...item, id:parent_id})" x-small link text>- repondre</v-btn>

            <small class="  v-size--x-small">
              <v-menu
                v-if="item.user.id == user.id"
                top
                left
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    v-bind="attrs"
                    small
                    style="float: right"
                    v-on="on"
                  >
                    <v-icon class="mr-1">
                      {{ icons.mdiDotsHorizontal }}
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <template>
                    <v-list-item
                      color="error"
                      @click="deleteComment(item, index)"
                    >
                      <v-list-item-title>Supprimer</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-list>
              </v-menu>
              <kola-toggle-component
                :subject="'postcomment'"
                :identify="item.id"
                :status="item.liked"
                :user_id="user.id"
                :counter="item.nbkola"
                @liked="(liked, nbkola)=>{item.liked = liked; item.nbkola = nbkola}"
              ></kola-toggle-component>
            </small>
          </v-list-item-subtitle>

        </v-list-item-content>
      </v-list-item>
    </template>
    <comment-form-component
      :answerto="newcomment"
      :user="user"
      @sent="commentSent"
    ></comment-form-component>
  </v-list>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import { computed, ref } from '@vue/composition-api/dist/vue-composition-api'
import { mdiDotsHorizontal, mdiDotsVertical, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { watch } from '@vue/composition-api'
import KolaToggleComponent from '@/components/KolaToggleComponent'
import UsernameComponent from "@/components/UsernameComponent.vue";
import CommentFormComponent from "@/components/CommentFormComponent.vue";
import Duration from "@/components/Duration.vue";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: 'CommentResponseListComponent',
  components: {UserAvatar, Duration, CommentFormComponent, UsernameComponent, KolaToggleComponent },
  props: {
    user: {
      type: Object,
      default: () => {

      },
    },
    nbreply: {
      type: Number | Boolean,
      default: () => 1,
    },
    newcomment: {
      type: Object,
      default: () => {
      },
    },
    parent_id: Number,
    limit: Number,
  },
  setup(props) {

    const repliedcomment = computed(() => props.newcomment)
    const postcommentll = ref({ listEntity: [] })
    const init = () => {
      Drequest.api(`lazyloading.postcomment?dfilters=on&parent_id:eq=${props.parent_id}`)
        .param({
          next: 1,
          'user_id': props.user.id,
          per_page: props.limit,
        })
        .get(response => {
          console.log(response)
          postcommentll.value = response
        })
    }
    if (props.nbreply > 0) {
      init()
    }

    watch(repliedcomment, (val, old) => {
      console.log(val, old)

      if (val && val.parent_id == props.parent_id) {
        postcommentll.value.listEntity.push(val)
      }

    })

    const deleteComment = (item, index) => {
      if (!confirm('Ce commentaire va etre supprime.\n\n Vous confirmez cette action?')) {
        return 0
      }

      Drequest.api(`delete.postcomment?id=${item.id}`)
        .get(response => {
          console.log(response)
          postcommentll.value.listEntity.splice(index, 1)
        })
    }

    const commentSent = response => {
      console.log(response)
      //if (!response.postcomment.parent_id) {
        postcommentll.value.listEntity.push(response.postcomment)
      /*} else {
        postcomment.value.nbreply += 1
        commentresponse.value = response.postcomment
      }*/
      //postcomment.value ={}
    }

    return {
      commentSent,
      deleteComment,
      postcommentll,
      icons: {
        mdiDotsHorizontal,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiDotsVertical,
      },
    }
  }
}
</script>

<style scoped>

</style>
