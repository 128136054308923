<template>
  <div class="">
    <slot v-if="hnotification.listEntity.length == 0" name="empty"></slot>
    <v-list v-else three-line  >

      <slot name="title"></slot>
      <template v-for="(item, index) in hnotification.listEntity">
        <v-divider
          :key="'d-'+index"
        ></v-divider>

        <v-list-item class="ma-0 pa-0"
                     :key="item.id"
                     :to="redirect(item)"
                     dense
        >
          <v-list-item-avatar class="">
            <v-img width="30"
              v-if="item.avatar"
              :src="require('@/assets/images/avatars/'+item.avatar)"
            ></v-img>
            <v-img width="30"
              v-else
              :src="require('@/assets/images/avatars/logo.png')"
            ></v-img>
          </v-list-item-avatar>

          <v-list-item-content class=" ma-0 pa-0 ">
            <template v-if="item.notification">
              {{ item.notification }}
            </template>
            <template v-else>
              {{ item.content }}
            </template>
            <v-list-item-subtitle>
              {{ item.created_at }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if="item.entity !== 'follow'">
            <img
              class="mr-3"
              width="50"
              :src="env+'uploads/'+item.path+'/'+item.image"
            >

          </v-list-item-action>
          <v-list-item-action v-else>
            <img
              class="mr-3"
              width="50"
              :src="require('@/assets/images/avatars/'+item.avatar)"
            >
          </v-list-item-action>
        </v-list-item>
      </template>
      <slot name="more"></slot>
    </v-list>

  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiFacebook,
  mdiClose,
  mdiArrowRight,
  mdiCreditCardOutline,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from '@mdi/js'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import {useRouter} from '@/utils'

export default {
  components: {},
  props: ['user', 'limit'],
  setup(props) {
    const env = Drequest.__env
    const subscription = ref({})
    const hnotification = ref({listEntity: []})
    const dialog = ref({
      newrefill: false,
      snack: false,
      confirm: false,
      loading: false,
    })

    const {router} = useRouter()
    const route = router.currentRoute
    if (route.params.option) dialog.value.newrefill = true

    const init = () => {
      Drequest.api('notification.list?dfilters=on&dsort=id desc&user_id:eq=' + props.user.id)
        .param({
          'next': 1,
          'per_page': props.limit ? props.limit : 30,
        })
        .get(response => {
          console.log(response)
          hnotification.value = response
        })
    }
    init()

    const refilled = () => {
      dialog.value.newrefill = false
      dialog.value.snack = true
      init()
    }
    const redirect = (item) => {
      if (item.redirect)
        return item.redirect
      if (item.entity === 'post')
        return '/activity/' + item.entityid

      return '/social'
    }

    return {
      init,
      redirect,
      refilled,
      hnotification,
      subscription,
      dialog,
      env,

      icons: {
        mdiClose,
        mdiEyeOutline,
        mdiArrowRight,
        mdiCreditCardOutline,
        mdiEyeOffOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
